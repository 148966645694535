import Vue from 'vue'
import Snackbar from '@/components/Snackbar'

const getDefaultState = () => {
  return {
    identifiersDetails: {},
    identifiersTaxTypes: [],
    bvdIdentifiersDetails: {},

    selectableGridData: []
  }
}

export default {
  namespaced: true,
  state: getDefaultState(),
  actions: {
    async getIdentifiersDetails({ commit }, sgaId) {
      try {
        const response = await Vue.prototype.$http.get(
          `/dmp/identifiers?sgaId=${sgaId}`
        )

        if (response?.data && response.data?.status === 200) {
          if (response.data?.data) {
            commit('SET_IDENTIFIERS_DETAILS', response.data.data)
          }
        } else {
          // Reset store
          commit('SET_IDENTIFIERS_DETAILS', {})
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    async updateIdentifiersDetails({ commit }, updateIdentifiersDetails) {
      try {
        const { sgaId, identifiersFormFields } = updateIdentifiersDetails

        const response = await Vue.prototype.$http.put(
          `/dmp/identifiers?sgaId=${sgaId}`,
          identifiersFormFields
        )

        if (response?.data && response.data?.status === 200) {
          if (response.data?.data) {
            commit('SET_IDENTIFIERS_DETAILS', response.data.data)
          }
        }
        // Returned response for status of submission
        return response?.data?.status
      } catch (error) {
        throw new Error(error)
      }
    },
    async getIdentifiersTaxTypes({ commit }, countryIdList = []) {
      try {
        commit('SET_IDENTIFIER_TAX_TYPES', [])
        const response = await Vue.prototype.$http.get(
          `/dmp/identifiers/tax-type?countryIds=${countryIdList.join(
            '&countryIds='
          )}`
        )
        if (response?.data && response.data?.status === 200) {
          if (response.data?.data) {
            commit('SET_IDENTIFIER_TAX_TYPES', response.data.data)
          }
        }
      } catch (error) {
        throw new Error(error)
      }
    },

    async getExternalLEIList({ commit }, entityName) {
      try {
        commit('SET_SELECTABLE_GRID_DATA', [])

        // Filter out words ony
        let entityNameWords = entityName
          .split(/\s+/)
          .map((part) => part.replace(/[^\w\s]/g, ''))
          .filter(Boolean)

        const response = await Vue.prototype.$http.post(
          `/dmp/integration?type=lei`,
          {
            params: {
              'filter[entity.names]': entityNameWords.join(','),
              'page[size]': '10',
              'page[number]': '1'
            }
          }
        )

        if (response?.data && response.data?.status === 200) {
          if (response.data?.data) {
            const leiFormattedData = response.data?.data?.map((item) => {
              return {
                LEI: item.lei,
                'Legal Entity Name': item.legalEntityName,
                'Other Names': item.otherNames,
                Status: item.status,
                'Legal Structure': item.legalStructure
              }
            })
            commit('SET_SELECTABLE_GRID_DATA', leiFormattedData)
          }
        } else {
          // If status is not 200, reset grid store
          Snackbar({
            message: 'Failed to fetch data.',
            type: 'is-danger'
          })
          commit('SET_SELECTABLE_GRID_DATA', [])
        }
      } catch (error) {
        throw new Error(error)
      }
    },

    async getExternalCRDList({ commit }, entityName) {
      try {
        commit('SET_SELECTABLE_GRID_DATA', [])

        const response = await Vue.prototype.$http.post(
          `/dmp/integration?type=crd`,
          {
            params: {
              query: entityName.split(' ').join(' '),
              hl: true,
              nrows: 10,
              start: 0,
              r: 25,
              sort: 'score+desc',
              wt: 'json'
            }
          }
        )

        if (response?.data && response.data?.status === 200) {
          if (response.data?.data) {
            const modifiedData = response.data?.data?.map((item) => ({
              CRD: item.crd,
              'Legal Entity Name': item.legalEntityName
            }))
            commit('SET_SELECTABLE_GRID_DATA', modifiedData)
          }
        }
      } catch (error) {
        throw new Error(error)
      }
    },
    resetIdentifiersDetails({ commit }) {
      commit('SET_IDENTIFIERS_DETAILS', {})
    },
    patchIdentifiersDetails({ commit }, bvdIdentifiersDetails) {
      commit('SET_BVD_IDENTIFIERS_DETAILS', bvdIdentifiersDetails)
    }
  },
  mutations: {
    SET_IDENTIFIERS_DETAILS(state, identifiersDetails) {
      state.identifiersDetails = { ...identifiersDetails }
    },
    SET_IDENTIFIER_TAX_TYPES(state, taxTypes) {
      state.identifiersTaxTypes = taxTypes.map((option) => {
        const taxTypesObj = {}
        taxTypesObj.value = option.taxTypeId
        taxTypesObj.text = `${option.taxTypeCode} - ${option.taxType}`
        return taxTypesObj
      })
    },
    SET_BVD_IDENTIFIERS_DETAILS(state, bvdIdentifiersDetails) {
      state.bvdIdentifiersDetails = { ...bvdIdentifiersDetails }
    },

    SET_SELECTABLE_GRID_DATA(state, selectableGridData) {
      state.selectableGridData = selectableGridData
    }
  }
}
